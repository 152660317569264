import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { ModalData, ModalRef } from '@purespectrum1/ui/modal';
import { BulkOptionConfirmationModalData } from './bulk-option.interface';
import {
  McqbResponse,
  SurveyListing,
} from '../../shared/services/buyer-api/survey.interface';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  from,
  iif,
  merge,
  of,
  partition,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  share,
  switchMap,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import { ProjectManagers } from '../../operator/user-service/user.interface';
import { BulkView, BulkViewColumn } from '../bulk-edit/domain/types';
import { BuyerApiService } from '../../shared/services/buyer-api/survey.service';
import { notifyMessage } from '../../constants/notify-message';
import { SurveyStatus } from '../../utils/survey-status';
import {
  BulkEditBillingNumberPayload,
  BulkPayload,
} from '../bulk-edit/domain/bulk-payload-formatter';
import { BillingNumberRules } from '@purespectrum1/ui/marketplace/shared/interfaces/billing-rules.interfaces';
import { BillingRulesService } from '../bulk-edit/bulk-edit-container/bulk-edit-container.service';
import { AuthService } from '../../auth/auth.service';
import {
  BulkEditPayloadValue,
  EditableSurvey,
  SurveyModePending,
} from '../bulk-edit/types';
import { LayoutConstants } from '../../layout/layout-constants';
import {
  ComposedState,
  EditableSurveyStateComposer,
} from './domain/editable-survey-state-composer';
import { BulkEditStatusMapper } from '../bulk-edit/domain/bulk-edit-status-mapper';
import { INVOICE_TYPE } from '../../constants/invoice-type';
import {
  BulkViewBillingActionColumn,
  BulkViewCostColumn,
  BulkViewInfoColumn,
  BulkViewStatusColumn,
} from '../bulk-edit/domain/bulk-view-columns';
import { SURVEY_STATUS } from '../../constants/survey-status';
import { Constants } from '../../create-surveys/create-survey.constants';
import { Router } from '@angular/router';
import { SurveyRouterLinkPipe } from '../pipes/survey-router-link.pipe';
import { EachCountry } from '../../shared/interfaces/create-surveys.interface';

@Component({
  selector: 'ps-bulk-option-confirmation',
  templateUrl: './bulk-option-confirmation.component.html',
  styleUrls: ['./bulk-option-confirmation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkOptionConfirmationComponent implements OnInit {
  public data: BulkOptionConfirmationModalData = this._modalData;

  public surveyIds: Pick<SurveyListing, 'surveyId'>[] = [];
  public surveys$!: Observable<EditableSurvey[]>;
  public managers: ProjectManagers[] = [];
  public view!: BulkView;
  public columns$!: Observable<BulkViewColumn[]>;
  public showError: boolean = false;
  public disableButton: boolean = false;
  public billingRules$!: Observable<BillingNumberRules>;
  public MAX_CPI_THRESHOLD = LayoutConstants.MAX_CPI_THRESHOLD;
  public AVERAGE_MARGIN = Constants.AVERAGE_VARIABLE_MARGIN;
  public isCpiConfirmed: boolean = false;
  public MAX_CPI_THRESHOLD_SURPASSED_ERROR =
    notifyMessage.CPI_CONFIRMATION_ERROR;
  public surveyStatus: Array<[string, number]> = [];
  public showBillingInputForEdit = false;

  private _surveysCommand$ = new BehaviorSubject<EditableSurvey[]>([]);
  private _updateCommand$ = new Subject<EditableSurvey[]>();
  private _feedbackCommand$ = new Subject<EditableSurvey>();
  private _deleteCommand$ = new Subject<EditableSurvey>();
  private _closeCommand$ = new Subject();

  private _subscription: Subscription = new Subscription();
  private _surveys: EditableSurvey[] = [];

  public get value() {
    return this._modalData.value;
  }

  public get surveys() {
    return this._modalData.surveys;
  }
  public countries: EachCountry[] = this.data.countries;
  constructor(
    @Inject(ModalData)
    private _modalData: BulkOptionConfirmationModalData,
    private _modalRef: ModalRef<BulkOptionConfirmationComponent, boolean>,
    private _buyerApiService: BuyerApiService,
    private _toast: ToasterService,
    private _billingRulesService: BillingRulesService,
    private _auth: AuthService,
    private readonly _route: Router
  ) {}

  public ngOnInit() {
    this.view = this._modalData.view;
    this.managers = this._modalData.managers;

    const filteredStatus = new BulkEditStatusMapper(
      this._auth.companyConfig.generateInvoice || INVOICE_TYPE.MONTHLY
    ).list();
    this.surveyStatus = Object.entries(filteredStatus);

    const initial: EditableSurvey[] = this._modalData.surveys.map((survey) => {
      const state = new EditableSurveyStateComposer(survey, this.value);
      return state.pending();
    });

    const feedback$ = this._feedbackCommand$.pipe(
      map((feedback) => {
        return this._surveys.map((survey) => {
          if (survey.id === feedback.id) {
            return feedback;
          }
          return survey;
        });
      })
    );

    const delete$ = this._deleteCommand$.pipe(
      map(({ id }) => this._surveys.filter((survey) => survey.id !== id))
    );

    const update$ = this._updateCommand$.pipe(
      switchMap(() => this._validations())
    );

    const [updateWithError$, updateWithSuccess$] = partition(
      update$,
      (surveys) => this._isSurveysHaveError(surveys)
    );

    // Handle updates with errors
    const errorStream$ = updateWithError$.pipe(
      tap(this._handleValidationErrors)
    );

    // Handle updates with success
    const successStream$ = updateWithSuccess$.pipe(
      switchMap((surveys) =>
        from(surveys).pipe(
          mergeMap((state) => {
            if (this._isSurveysHaveError([state], true)) {
              return of(state);
            }
            const { survey } = state;
            const finded = this._modalData.payloads.find(
              (payload) => payload.survey() === survey.surveyId
            )!;
            const editable = this._surveys.find(
              ({ id }) => id === survey.surveyId
            )!;
            const payload = finded.update(editable.value);
            return this.data.type === 'deleteDraft'
              ? this._delete(payload, survey)
              : this._update(payload, survey);
          }),
          toArray()
        )
      )
    );

    // Merge the two streams
    const finalUpdate$ = merge(errorStream$, successStream$).pipe(
      filter((surveys) => !this._isSurveysHaveError(surveys, true)),
      share()
    );

    finalUpdate$.subscribe({
      next: (surveys) => {
        const error = surveys.some((survey) => survey.status === 'error');
        if (!error) {
          this._toast.success(
            notifyMessage.successMessage.BULK_EDIT.OPERATION_COMPLETED.replace(
              '<OPERATION_TYPE>',
              this._modalData.view.operation()
            )
          );
        } else {
          this._toast.error(
            notifyMessage.successMessage.BULK_EDIT.OPERATION_FAILED
          );
        }
      },
    });

    this.surveys$ = merge(this._surveysCommand$, feedback$, delete$).pipe(
      switchMap((editable) =>
        from(editable).pipe(
          filter((survey) =>
            ['pending', 'error', 'warning'].includes(survey.status)
          ),
          toArray()
        )
      ),
      tap((surveys) => {
        this._surveys = surveys;
      })
    );

    const close$ = this._closeCommand$.pipe(
      withLatestFrom(this.surveys$),
      map(([_, surveys]) => surveys)
    );

    merge(close$, finalUpdate$)
      .pipe(
        map((surveys) => surveys.some((survey) => survey.status === 'edited'))
      )
      .subscribe((close) => this._modalRef.close(close));
    this._fetchBillingNumberRules();
    this._surveysCommand$.next(initial);
    this._subscription.add(this._closeCommand$);

    this.columns$ = this.surveys$.pipe(
      switchMap((surveys) => this._handleDynamicColumns(surveys)),
      map(() => this.view.columns())
    );
  }

  private _handleValidationErrors = (surveys: EditableSurvey[]): void => {
    surveys.forEach((survey) => {
      if (this._isSurveyInErrorState(survey, false)) {
        if ('error' in survey && survey.status === 'error') {
          this._toast.error(survey.error as string);
        }
      }

      if (Array.isArray(survey.survey.mc)) {
        survey.survey.mc.forEach((childSurvey) => {
          if (childSurvey.status === 'error') {
            this._toast.error(childSurvey.message.ps_api_response_message);
          }
        });
      }
    });
  };

  private _isSurveysHaveError(
    surveys: EditableSurvey[],
    completeErrorCheck: boolean = false
  ): boolean {
    return surveys.some((survey) =>
      this._isSurveyInErrorState(survey, completeErrorCheck)
    );
  }

  private _isSurveyInErrorState(
    survey: EditableSurvey,
    completeErrorCheck: boolean
  ): boolean {
    const hasErrorOrWarning =
      survey.status === 'error' ||
      (survey.status === 'warning' && !!survey.isBelowAverageMargin);
    const validPositions = completeErrorCheck
      ? ['toaster', 'info-column']
      : ['toaster'];

    if (
      hasErrorOrWarning &&
      'position' in survey &&
      validPositions.includes(survey.position)
    ) {
      return true;
    }

    return false;
  }

  private _validations(): Observable<EditableSurvey[]> {
    this._surveys.forEach((item) => {
      if (item.status === 'pending') {
        const state = new EditableSurveyStateComposer(item.survey, item.value);
        const updatedSurvey = this.view.validate(state, 'submission');
        this._feedbackCommand$.next(updatedSurvey);
      }
    });
    return of(this._surveys);
  }

  public onDeleteSurvey(survey: EditableSurvey) {
    this._deleteCommand$.next(survey);
  }

  public onClose() {
    this._closeCommand$.next();
  }

  public onConfirm() {
    this._updateCommand$.next(this._surveys);
  }

  public displayStatus(surveyStatusNumber: number): string {
    return SurveyStatus[surveyStatusNumber];
  }

  public displayManger(selected: ProjectManagers): string {
    return (
      this.managers.find((m) => m.id === selected.id)?.name || 'Select one'
    );
  }

  public billingNumberEnabled() {
    return !!this._auth.buyerConfig?.enableBillingNumberRules;
  }

  private _fetchBillingNumberRules(): void {
    this.billingRules$ = this._billingRulesService.fetchRules(
      this._auth.user?.cmp || 0
    );
  }

  private _update(
    data: BulkPayload<BulkEditPayloadValue>,
    survey: SurveyListing
  ) {
    const observable = this._buyerApiService
      .patchSurvey(data.survey(), data.payload())
      .pipe(
        map((response) => this.processSurveyResponse(response, survey, data))
      );
    return this.processResponse(observable, data, survey);
  }

  private _delete(
    data: BulkPayload<BulkEditPayloadValue>,
    survey: SurveyListing
  ) {
    const observable = this._buyerApiService
      .deleteDraftSurvey(data.survey())
      .pipe(map(() => new EditableSurveyStateComposer(survey, data.value())));
    return this.processResponse(observable, data, survey);
  }

  public processResponse(
    observable: Observable<ComposedState>,
    data: BulkPayload<BulkEditPayloadValue>,
    survey: SurveyListing
  ) {
    return observable.pipe(
      map((state) => state.edited()),
      tap((response) => this._feedbackCommand$.next(response)),
      catchError((error) => {
        const response = new EditableSurveyStateComposer(
          survey,
          data.value()
        ).error(error?.error?.ps_api_response_message || error);
        this._feedbackCommand$.next(response);
        return of(response);
      })
    );
  }

  public onTableCellClicked(survey: SurveyListing): void {
    const route = new SurveyRouterLinkPipe().transform(survey);
    const url = this._route.serializeUrl(this._route.createUrlTree(route));
    window.open(url, '_blank');
  }

  public onValueChange(item: EditableSurvey, value: BulkEditPayloadValue) {
    const state = new EditableSurveyStateComposer(item.survey, value);
    const updatedSurvey = this.view.validate(state, 'value-change');
    this._feedbackCommand$.next(updatedSurvey);
  }

  public billingNumberOnAlredyInvoicedSurvey(
    billingCode: string
  ): Observable<String> {
    return this._buyerApiService.getSurveyAlredyInvoicedWithPONumber(
      billingCode
    );
  }

  public onBillingNumberChange(value: string): void {
    this.billingNumberOnAlredyInvoicedSurvey(value).subscribe({
      error: () => {
        this._toast.warning(
          notifyMessage.errorMessage.BULK_EDIT.BILLING_CODE_ALREADY_USED
        );
      },
    });
  }

  public onCpiOrMarginConfirmation(survey: EditableSurvey) {
    const state = new EditableSurveyStateComposer(survey.survey, survey.value);
    const updatedSurvey: EditableSurvey = state.pending();
    this._feedbackCommand$.next(updatedSurvey);
  }

  public concatParentWithChilds(
    parent: SurveyListing,
    child: SurveyListing
  ): SurveyListing {
    const mcqb = child.mcqb;
    if (mcqb) {
      return { ...parent, mcqb };
    }
    return parent;
  }

  public handleMcqbError(
    mcqbResponse: McqbResponse[],
    survey: SurveyListing
  ): void {
    const matchingSurveys = this._getMatchingSurveys(mcqbResponse, survey);

    const combinedResponses = mcqbResponse.map((response) => {
      const matchingSurvey = matchingSurveys.find(
        (s) => s.surveyId === response.surveyId
      );
      return {
        ...response,
        survey: matchingSurvey,
      };
    });

    const errorResponses = combinedResponses.filter(
      (response) => response.status === 'error'
    );

    const errorObject = this.constructErrorMessage(errorResponses);

    throw new Error(JSON.stringify(errorObject));
  }

  public parseError(
    error: { message: string },
    survey: SurveyListing,
    needFormat = false
  ) {
    if (!needFormat) {
      return { message: error };
    }

    if (survey.mc?.surveys?.length && needFormat) {
      try {
        return JSON.parse(error.message);
      } catch (e) {
        return false;
      }
    }
  }
  public constructErrorMessage(errorResponses: McqbResponse[]): {
    message: string;
    details?: string[];
  } {
    const response = errorResponses
      .filter((r) => !r.message?.ps_api_response_message.includes('PATCH'))
      .map((r) => {
        const locale = r.survey
          ? `${r.survey.countryCode} - ${r.survey.languageCode}`
          : 'unknown locale';
        return `${r.message?.ps_api_response_message} for this local: ${locale}`;
      });

    const patchErrors = errorResponses.filter((r) =>
      r.message?.ps_api_response_message.includes('PATCH')
    );

    if (patchErrors.length > 0) {
      const locales = patchErrors
        .map((r) => {
          const locale = r.survey
            ? `${r.survey.countryCode} - ${r.survey.languageCode}`
            : 'unknown locale';
          return locale;
        })
        .join(', ');

      response.push(
        `We got an error while changing the status for these locales: ${locales}. Check the survey and try again.`
      );
    }

    return { message: 'Multiple errors occurred', details: response };
  }

  private _getMatchingSurveys(
    mcqbResponse: McqbResponse[],
    survey: SurveyListing
  ) {
    const surveyIds = mcqbResponse.map((response) => response.surveyId);
    return (
      survey?.mc?.surveys?.filter((s) => surveyIds.includes(s.surveyId)) || []
    );
  }

  public processSurveyResponse(
    response: SurveyListing,
    survey: SurveyListing,
    data: BulkPayload<BulkEditPayloadValue>
  ): ComposedState {
    const updatedSurvey = this.concatParentWithChilds(survey, response);
    const mcqbResponse = updatedSurvey?.mcqb?.filter(
      (s) => s.status === 'error'
    );

    if (mcqbResponse && mcqbResponse.length > 0) {
      this.handleMcqbError(mcqbResponse, survey);
    }

    return new EditableSurveyStateComposer(
      updatedSurvey,
      data.value()
    ) as ComposedState;
  }

  private _handleDynamicColumns(
    surveys: EditableSurvey[]
  ): Observable<EditableSurvey[]> {
    return iif(
      () => this.view.operation() === 'Edit STATUS',
      this._handleBillingNumberColumnForBulkInvoice(surveys),
      of(true)
    ).pipe(
      switchMap(() => this._handleInfoColumn(surveys)),
      map(() => surveys)
    );
  }

  private _handleInfoColumn(surveys: EditableSurvey[]): Observable<boolean> {
    return of(surveys).pipe(
      map(() =>
        surveys.some(
          (s) => s.status === 'error' && s.position === 'info-column'
        )
      ),
      tap((hasInfo) => {
        if (hasInfo) {
          this.view.addColumn(new BulkViewInfoColumn());
          return;
        }
        this.view.removeColumn(new BulkViewInfoColumn());
      })
    );
  }

  private _handleBillingNumberColumnForBulkInvoice(
    surveys: EditableSurvey[]
  ): Observable<boolean> {
    return of(surveys).pipe(
      map(() => surveys.some((s) => Number(s.value) === SURVEY_STATUS.INVOICE)),
      tap((hasEditableBillingColumn) => {
        // When it's invoice selection in any of the surveys, add billing column and remove cost column
        const editableBillingColumn = new BulkViewBillingActionColumn();
        const editableStatusColumn = new BulkViewStatusColumn(true);
        const costColumn = new BulkViewCostColumn();

        if (hasEditableBillingColumn) {
          this.view.addColumn(editableBillingColumn, editableStatusColumn);
          this.view.removeColumn(costColumn);
          return;
        }

        this.view.addColumn(costColumn, editableStatusColumn);
        this.view.removeColumn(editableBillingColumn);
      })
    );
  }

  public activateBillingState(
    item: EditableSurvey,
    billingState: 'view' | 'edit'
  ): void {
    const state = new EditableSurveyStateComposer(item.survey, item.value);
    const surveyMode: SurveyModePending = {
      billing_id: billingState,
    };
    const updatedSurvey: EditableSurvey = state.pending(surveyMode);
    this._feedbackCommand$.next(updatedSurvey);
  }

  public updateBillingNumberInSurvey(
    item: EditableSurvey,
    newBillingNum: string
  ): void {
    const state = new EditableSurveyStateComposer(item.survey, item.value);
    const surveyMode: SurveyModePending = {
      billing_id: 'view',
    };
    const updatedSurvey: EditableSurvey = state.pending(surveyMode);
    updatedSurvey.survey.billing_id = newBillingNum;
    this._feedbackCommand$.next(updatedSurvey);
  }

  public onBillingValueUpdate(
    item: EditableSurvey,
    newBillingNum: string
  ): void {
    const data = new BulkEditBillingNumberPayload(
      item.survey.surveyId,
      newBillingNum
    );
    this._buyerApiService
      .patchSurvey(data.survey(), data.payload())
      .pipe(tap(() => this.activateBillingState(item, 'view')))
      .subscribe({
        next: () => {
          this._toast.success(
            notifyMessage.successMessage.BILLING_NUMBER.UPDATED
          );
          this.updateBillingNumberInSurvey(item, newBillingNum);
        },
        error: (error) => {
          const errorMessage = error.error.ps_api_response_message;
          this._toast.error(errorMessage);
        },
      });
  }

  public isMarginEditable(item: EditableSurvey) {
    return (
      item.survey?.is_enable_margin_override &&
      !item.survey?.manual_scpi_override_enabled &&
      !item.survey.is_enable_margin_max
    );
  }
}
