<ps-ui-modal [showButtonClose]="false" class="bulk-option-confirmation">
  <ps-ui-modal-title class="title">
    <button
      type="button"
      class="btn-close status-modal-close-button"
      aria-label="Close"
      (click)="onClose()"
    ></button>
    <h5 psUiModalTitle [innerHTML]="view.title()"></h5>
    <p psUiModalTitle class="sub-title">{{ view.subTitle() }}</p>
  </ps-ui-modal-title>

  <ps-ui-modal-body #container>
    <ng-container *ngIf="surveys$ | async as state">
      <ps-ui-table [data]="state">
        <ng-container *ngIf="columns$ | async as columns">
          <ng-container
            *ngFor="let column of columns"
            psUiTableColumn
            title="{{ column.header() }}"
            [width]="500"
          >
            <ng-template psUiTableColumnCustomHeader>
              <div style="cursor: auto">
                <ng-container [ngSwitch]="column.type()">
                  <ng-container *ngSwitchDefault>
                    {{ column.header() }}
                  </ng-container>

                  <ng-container
                    *ngSwitchCase="
                      ['billing', 'billing-action']
                        | switchMultiCase : column.type()
                    "
                  >
                    <div style="display: inline-flex">
                      {{ column.header() }}

                      <ps-billing-number-tooltip
                        *ngIf="billingNumberEnabled"
                        [rules]="billingRules$ | async"
                      ></ps-billing-number-tooltip>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-template>
            <ng-template psUiTableCellTemplate let-item>
              <ng-container [ngSwitch]="column.type()">
                <ng-container *ngSwitchCase="'text'">
                  <div (click)="onTableCellClicked(item.survey)">
                    {{ column.prefix(item.survey) }}
                    {{ column.content(item.survey) }}
                    {{ column.suffix(item.survey) }}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'country-language'">
                  <ps-multi-country-info-listing
                    [countries]="countries"
                    [mcSvpChildSurveys]="item.survey.mc?.surveys"
                    *ngIf="
                      item.survey?.mc?.SVP &&
                      item.survey?.mc?.parent &&
                      item.survey?.mc?.surveys?.length
                    "
                  >
                    <ng-template psUiTableCellTemplate>
                      <div
                        class="multiple-label-container d-flex justify-content-center"
                      >
                        <div class="mcqb-survey-count pb-0 rounded-2">
                          {{ item.survey.mc.surveys.length }}
                        </div>
                        <div class="multiple-label">Multiple</div>
                      </div>
                    </ng-template>
                    <ng-template
                      psMultiCountryColumnContent
                      classList="px-0 py-2 country-name-column"
                      let-survey
                    >
                      <span> {{ survey.countryName }} </span>
                    </ng-template>

                    <ng-template
                      psMultiCountryColumnContent
                      classList="px-0 py-2"
                      let-survey
                    >
                      <span class="p-1 fw-bold language-icon">
                        {{ survey.languageCode | uppercase }}
                      </span>
                    </ng-template>
                  </ps-multi-country-info-listing>
                  <div>{{ item.survey.countryLang }}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'cpi'">
                  <div class="d-flex align-items-center justify-content-around">
                    <div
                      class="d-flex align-items-center justify-content-around cpi-input-container"
                    >
                      {{ column.prefix(item.survey) }}
                      <input
                        type="number"
                        class="input-with-indicator"
                        [ngModel]="item.value"
                        (ngModelChange)="onValueChange(item, $event)"
                        psInputDecimalRestriction
                        [ngClass]="{
                          'threshold-surpassed': item.value > MAX_CPI_THRESHOLD
                        }"
                      />
                    </div>
                    <ng-container
                      *ngIf="
                        item.status === 'error' &&
                        item.error === MAX_CPI_THRESHOLD_SURPASSED_ERROR
                      "
                    >
                      <div
                        class="d-flex align-items-center justify-content-around cpi-confirmation-container"
                      >
                        <div class="tooltip-visible cpi-confirmation-tooltip">
                          <div
                            class="fa fa-exclamation-triangle exclamation-icon tootip-setting-info setting-info"
                          >
                            <p>
                              <span>
                                Please confirm the Override CPI value you have
                                entered is accurate.
                              </span>
                            </p>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary cpi-confirmation-btn"
                          (click)="onCpiOrMarginConfirmation(item)"
                        >
                          Confirm
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'margin'">
                  {{ column.prefix(item.survey) }}
                  <div class="d-flex justify-content-center align-items-center">
                    <input
                      type="number"
                      class="input-with-indicator"
                      [ngModel]="
                        item.survey?.is_enable_margin_override ? item.value : ''
                      "
                      (ngModelChange)="onValueChange(item, $event)"
                      [disabled]="!isMarginEditable(item)"
                      [ngClass]="{
                        'threshold-surpassed': item.value < AVERAGE_MARGIN
                      }"
                    />
                    %
                    <ng-container *ngIf="item.isBelowAverageMargin">
                      <span class="help-tip mx-2">
                        <i
                          class="fa fa-exclamation-circle error-icon"
                          aria-hidden="true"
                        ></i>
                        <p>
                          You have entered a Target Margin value that is below
                          the average Margin set for this account.
                        </p>
                      </span>

                      <button
                        class="btn btn-primary"
                        (click)="onCpiOrMarginConfirmation(item)"
                      >
                        confirm
                      </button>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'pm'">
                  <ps-ui-select-dropdown
                    id="with-search"
                    [dropdownItems]="managers"
                    [field]="'name'"
                    [label]="displayManger(item.value)"
                    [boundaries]="container.getBoundingClientRect()"
                    (selectedItem)="onValueChange(item, $event)"
                  ></ps-ui-select-dropdown>
                </ng-container>

                <ng-container *ngSwitchCase="'po'">
                  <ps-ui-select-dropdown
                    id="with-search"
                    [dropdownItems]="managers"
                    [field]="'name'"
                    [label]="displayManger(item.value)"
                    [boundaries]="container.getBoundingClientRect()"
                    (selectedItem)="onValueChange(item, $event)"
                  ></ps-ui-select-dropdown>
                </ng-container>

                <ng-container *ngSwitchCase="'billing'">
                  <input
                    type="textarea"
                    [value]="value"
                    class="billing-number"
                    [ngModel]="item.value"
                    (focusout)="onBillingNumberChange(item.value)"
                    (ngModelChange)="onValueChange(item, $event)"
                  />
                </ng-container>

                <ng-container *ngSwitchCase="'status'">
                  <button
                    class="stats-menu-btn"
                    [disabled]="!column.editable()"
                    [psUiDropdownToggleFor]="status"
                    [ngClass]="column.editable() ? 'activeBtn' : 'disabledBtn'"
                  >
                    {{
                      column.editable()
                        ? displayStatus(item.value)
                        : column.content(item.survey)
                    }}
                  </button>

                  <ps-ui-dropdown
                    class="status-dropdown"
                    [boundaries]="container.getBoundingClientRect()"
                    #status="psUiDropdown"
                  >
                    <button
                      *ngFor="let status of surveyStatus"
                      (click)="onValueChange(item, status[1])"
                      psUiDropdownItem
                    >
                      {{ status[0] }}
                    </button>
                  </ps-ui-dropdown>
                </ng-container>

                <ng-container *ngSwitchCase="'billing-action'">
                  <div
                    *ngIf="item.surveyMode.billing_id === 'view'"
                    title="click to edit"
                    class="d-flex align-items-center justify-content-center"
                  >
                    {{ column.content(item.survey) }}
                    <span
                      class="fa fa-pencil mx-1"
                      (click)="activateBillingState(item, 'edit')"
                    >
                    </span>
                  </div>

                  <div
                    class="d-flex align-items-center justify-content-center"
                    *ngIf="item.surveyMode.billing_id === 'edit'"
                  >
                    <div class="mx-2">
                      <input
                        #billingActionInput
                        type="textarea"
                        [value]="column.content(item.survey, 'edit')"
                        class="billing-number-input"
                        [ngModel]="column.content(item.survey, 'edit')"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center billing-action-edit-container"
                    >
                      <div
                        class="d-flex align-items-center fa fa-check glyphicon glyphicon-ok p-1 text-primary"
                        (click)="
                          onBillingValueUpdate(item, billingActionInput.value)
                        "
                      ></div>
                      <div
                        class="d-flex align-items-center fa fa-remove glyphicon glyphicon-remove p-1 text-danger"
                        (click)="activateBillingState(item, 'view')"
                      ></div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'info'">
                  <ng-container *ngIf="item.error">
                    <span class="help-tip">
                      <i
                        class="fa fa-exclamation-circle error-icon"
                        aria-hidden="true"
                      ></i>
                      <ng-container
                        *ngIf="
                          parseError(item.error, item.survey, true)?.details;
                          else singleMessage
                        "
                      >
                        <ul>
                          <li
                            *ngFor="
                              let errorMsg of parseError(
                                item.error,
                                item.survey,
                                true
                              ).details
                            "
                          >
                            {{ errorMsg }}
                          </li>
                        </ul>
                      </ng-container>
                      <ng-template #singleMessage>
                        <p>
                          {{
                            parseError(item.error, item.survey, false).message
                          }}
                        </p>
                      </ng-template>
                    </span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-template>

            <ng-template *ngIf="view.footer()" psUiColumnFooter>
              {{ column.footer(state) }}
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container psUiTableColumn title="Options">
          <ng-template psUiTableCellTemplate let-survey>
            <button
              class="btn btn-outline-default dropdown-input-decorators no-box-shadow"
              type="button"
              (click)="onDeleteSurvey(survey)"
            >
              <i class="fa fa-trash"> </i>
            </button>
          </ng-template>
        </ng-container>
      </ps-ui-table>
    </ng-container>
  </ps-ui-modal-body>

  <ps-ui-modal-footer>
    <button type="button" class="btn leftBtn" (click)="onClose()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-default rightBtn"
      psUiButton
      [disabled]="disableButton"
      (click)="onConfirm()"
    >
      Approve
    </button>
  </ps-ui-modal-footer>
</ps-ui-modal>
