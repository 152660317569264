'use strict';

const trafficChannelsHelpHyperlink =
  'https://purespectrum.helpjuice.com/en_US/manage-surveys/traffic-channels';

export const configFeaturesDocLink =
  'https://docs.google.com/presentation/d/1Lm0zHEuSUPej00wXUURsDBkzZP5bzEGOj6kTOfKLKx0/edit#slide=id.g2cfd593c84b_1_87';

export const HIDDEN_CONFIGS_FOR_PRODUCTION: string[] = ['enableSimilarSurveys'];

export const blockedCountriesSectionIndex = 6;
export const blockedCountriesFeatureIndex = 0;

export const Constants = Object.freeze({
  CONFIG_FIELDS: [
    {
      section: 'CPI MANAGEMENT',
      features: [
        {
          name: 'Price Override',
          value: 'overridCmp',
          desc: 'It will enable price-override feature on supplier selection page on survey creation/edit.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'PurePrice Rates',
          value: 'enablePurePriceRates',
          desc: '02.02.23: PurePrice™ is a new pricing model that considers a project’s IR, LOI, field time, and audience targeting to generate a data driven price recommendation.',
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
    {
      section: 'FIELDING',
      features: [
        {
          name: 'Field Evenly Over Time',
          value: 'enableFeot',
          desc: `FEOT will auto activate <a target="_blank" href="${trafficChannelsHelpHyperlink}">TRAFFIC CHANNELS</a> on your clients account. We use Traffic Channels on FEOT to make sure that the Project does not go ‘stale’ in suppliers que of projects as the FEOT Project goes through its status changes.`,
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Traffic Channels',
          value: 'enableTrafficChannels',
          desc: 'This feature gives the buyer the ability to access the Traffic Channels',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Quota Grouping',
          value: 'enableNestingQuotaGrouping',
          desc: '01.11.22: This feature gives users the ability to nest screener questions from CORE/AT/GEO and create blocks of acceptable responses',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Quota Throttling',
          value: 'quotaThrottling',
          desc: 'It will enable Quota throttling.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Live Reconciliation',
          value: 'enableLiveRecon',
          desc: '01.03.23: DO NOT USE THIS FEATURE!  Allow the buyer to reconcile a survey multiple times, and reject a complete in real-time.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Supplier Grouping',
          value: 'enableSupplierGrouping',
          desc: '29.01.2021: With a growing list of suppliers, some buyers may choose to group suppliers together for a cleaner experience managing their projects',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Field Management Automation',
          value: 'enableAutoFieldManagement',
          desc: '15.01.2021: This feature makes automated updates to the Buyer’s surveys based on key performance metrics (EPC, CPI, Price Per Minute, Valid Starts, and Completes)',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Time Based Auto Pause',
          parent: true,
          parentValue: 'enableAutoFieldManagement',
          value: 'enableFMATimeBasedAutoPause',
          desc: '12.01.2023: DO NOT USE Will Pause Surveys that have not collected a start in 6 hours or a complete in 72 hours.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Automated Yield Management',
          parent: true,
          parentValue: 'enableAutoFieldManagement',
          value: 'enableAutomatedYieldManagement',
          desc: '12.01.2023: This enables features related to yield management by automatically pausing a Buyer’s surveys based on key performance metrics (EPC, CPI, Price Per Minute, Over Quotas and Buyer Drops).',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Auto Launch TC: Near Quota Complete',
          parent: true,
          parentValue: 'enableAutoFieldManagement',
          value: 'enableFMAAutoLaunchTCNearQuotaComplete',
          desc: '22.02.2021: This enables the automatic launch of Traffic Channels for specific quotas that are close to their goal.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Daily Auto Launch TC',
          parent: true,
          parentValue: 'enableAutoFieldManagement',
          value: 'enableFMADailyAutoLaunchTC',
          desc: '22.02.2021: This enables a daily launch of a Traffic Channel Replica for live surveys to maintain momentum for a project',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Simulated Yield Management',
          parent: true,
          parentValue: 'enableAutoFieldManagement',
          value: 'enableSimulatedYieldManagement',
          desc: '03.03.2021: This enables tracking of FMA health measures without activating the automated triggers so that you can analyze how FMA will behave on an account before activating.',
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
    {
      section: 'PROJECT MANAGEMENT',
      features: [
        {
          name: 'Filtering, Sorting and Bulk Edit',
          parentValue: 'enableDynamicDash',
          value: 'enableFilteringSortingBulkEdit',
          desc: '07.12.23: This feature allows the buyer to filtering and sorting the survey list from the main dashboard and additionally to enable multiple bulk operations.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Survey Health Metrics',
          value: 'enableHealthMetrics',
          desc: 'This feature will give users the ability to tracks and notifies users of project health by monitoring a set of KPIs',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Reconciliations',
          value: 'reconciliations',
          desc: 'It will enable reconciliation for buyer',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Dynamic Dashboard',
          value: 'enableDynamicDash',
          desc: 'This feature gives users the ability to arrange survey dashboard to desired preference.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'View by Project Manager',
          value: 'enableViewByPM',
          desc: '21.09.2022: This feature will give Buyers the ability to assign Project Managers to surveys and to filter the Dashboard by those assignments.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Required PM',
          parent: true,
          parentValue: 'enableViewByPM',
          value: 'enablePMRequired',
          desc: '9.11.2023: This makes assigning a Buyer Project Manager required in order to launch a Survey.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Mandatory Billing Number',
          value: 'enableMandatoryBillingNumber',
          desc: '21.9.2023: This feature makes the Billing number a mandatory field when the user launches the survey - this feature will be deactivated if Billing Number Rules is activated',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Billing Number Rules',
          value: 'enableBillingNumberRules',
          desc: '21.9.2023: This feature allows the buyer to decide the rules of the Billing number and can make mandatory the field - this feature, if activated, will deactivate the feature Mandatory Billing number.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Pace Tool',
          value: 'enablePaceTool',
          desc: `08/02/2024: DO NOT USE THIS FEATURE! This feature will give users access to the Pace Tool and Dashboard Widget`,
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
    {
      section: 'PROJECT SET UP',
      features: [
        {
          name: 'Include & Exclude Both',
          value: 'includeToo',
          desc: 'It will enable include & exclude functionality for the buyer on final page in survey creation/edit process. Exclude only functionality is enabled for all buyers by default.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Click Balancing',
          value: 'clickBal',
          desc: 'It will enable click balancing feature for the buyer on survey creation/edit.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Advance Targeting',
          value: 'advanceTarget',
          desc: 'It will enable advance targeting questions for the buyer on survey creation/edit.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Recontact',
          value: 'recontact',
          desc: 'It will enable recontact flow in UI for selected buyer.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Unique Buyer Entry Links',
          value: 'uniqBuyerEntryLink',
          desc: 'This is Unique Buyer Entry Links',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Template',
          value: 'enableTemplate',
          desc: '31.03.2022: This feature give buyers the option to use the Template Feature.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Multi-Country',
          value: 'enableMultiCountry',
          desc: '10.03.2022: Enable multi-country survey to a buyer account.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Enable Group Multi-Country',
          value: 'enableMultiCountrySingleProjectView',
          desc: '08/01/2024: This feature gives users the ability to create a multi-country project but combined in a single project.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Create/Edit Survey Workflow v.2.0',
          value: 'enableCreateSurveyV2',
          desc: `08/02/2024: DO NOT USE THIS FEATURE! This feature will enable the Create/Edit Survey Workflow using the New Modular Feasibility and Pricing Services.`,
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Enable Similar Surveys',
          value: 'enableSimilarSurveys',
          desc: '01/10/2024 | This feature enable a tool in survey creation that allow a user to understand how the survey created can be fielded based on the history of the survey launched and finalized in the past.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Enable search to all MarketPlace inventory.',
          parent: true,
          parentValue: 'enableSimilarSurveys',
          value: 'enableSearchAllMarketPlaceInventory',
          desc: '01/10/2024 | This allow the buyer to look at the similar surveys in all MarketPlace inventory in case the query return any result.',
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
    {
      section: 'QUALITY',
      features: [
        {
          name: 'Basic Token',
          value: 'enableBasicToken',
          desc: 'It will enable the basic token security setting for the buyer on the survey creation page.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Data Quality Questions',
          value: 'enableDataQualityQues',
          desc: 'This feature gives the buyer the ability to access the Data Quality Questions',
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
    {
      section: 'OTHER',
      features: [
        {
          name: 'Decipher Mapping',
          value: 'decipher',
          desc: "Decipher integration will be enabled for buyer, and will need some inputs beforehand.  Discuss with CS or service leads before enabling. Survey creation UI will show 'Import Decipher' option, and Quotas in Decipher can be mapped to MP Qualifications.",
          hasAdditionalInfo: true,
          disabled: false,
        },
        {
          name: 'API based Complete',
          value: 'enableApiOnlySurveyCompletes',
          desc: 'Completes will be marked using API’s, instead of standard redirects, and require specific code to be added in Survey XML. Discuss with CS leads or product team before enabling. Enabling Quota Code reporting may also be required.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'R/N',
          value: 'samplify',
          desc: 'It will enable R/N setings',
          hasAdditionalInfo: true,
          disabled: false,
        },
        {
          name: 'QBP',
          value: 'qbp',
          desc: 'It will enable Quota based pricing for buyer.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Quota Code Reporting',
          value: 'qlc',
          desc: '“API based complete” might also need to be enabled. Requires specific code to be added in Survey XML and redirects. Discuss with CS leads or product team before enabling.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Automatic Variable Mapping',
          value: 'enableUrlTranfrmUsr',
          desc: 'This feature gives the buyer the ability to turn off the automatic transaction variable mapping.',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'HyperTargeted',
          value: 'enableHyperTarget',
          desc: "Activating HyperTargeting will add 'HyperTargeted' to your clients list of suppliers.",
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Scheduled Field Time',
          value: 'enableScheduledLaunch',
          desc: 'This feature gives the buyer the ability to schedule the field time/launch time of a survey.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Survey External ID',
          value: 'enableSurveyExternalId',
          desc: 'This feature gives the buyer the ability to add an external project ID for a survey.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Nightcrawler',
          value: 'enableNightcrawler',
          desc: 'This feature gives the buyer the ability to access the Nightcrawler ',
          hasAdditionalInfo: true,
          disabled: false,
        },

        {
          name: 'Survey Level DQ Battery',
          value: 'enableSurveyDQBattery',
          desc: 'This feature gives the buyer the ability to turn on the data quality battery for specific surveys',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Advance Stats',
          value: 'advanceStats',
          desc: 'This feature gives the buyer the ability to access the Advance Stats under Supplier tab.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Question Library',
          value: 'enableQuestionLibrary',
          desc: 'This feature gives the buyer the ability to access the Question Library',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'Invoice Tab',
          value: 'enableInvoiceTab',
          desc: 'This feature gives the buyer the ability to see the invoice tab',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'Pricing Recomendation Feature',
          value: 'enablePriceRecomendation',
          desc: '23.11.2020: This feature is being tested on very specific accounts. It should only be enabled by the Product Team.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Click to Calculate',
          value: 'enableClickToCalculate',
          desc: '30.04.2021: This feature give buyers the option to toggle between Manual and Real Time processing of Feasibility and CPI.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Target Margin',
          value: 'enableTargetMargin',
          desc: ' 20.09.2021: This feature gives Service Operators the ability to manage projects by manipulating Buyer CPI and Margin.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Target Margin: Launch All',
          parent: true,
          parentValue: 'enableTargetMargin',
          value: 'enableTargetMarginLaunchAll',
          desc: '14.04.2022: This feature will launch all surveys on an account with Target Margin enabled.',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'Extra Core Qualification',
          value: 'enableExtraCoreQualification',
          desc: '30.05.2025: Enable extra qualification for some specific Country: Australia - Relationship',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Sharing',
          value: 'shareMarketplaceInsights',
          desc: 'This feature gives users the ability to share their Marketplace Account with an Insights Account.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Custom Respondent Experience',
          value: 'enableCRE',
          desc: '27.04.2022: This feature forces the respondent to always answer the zip code prescreen question. This will be applied to any survey created by this buyer.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Margin Maximization',
          value: 'enableMarginMax',
          desc: '26.07.2022: This feature makes automated updates to the CPI based on the health of the project',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'PureScore/RD Supplier Settings',
          value: 'enablePureScoreAndRD',
          desc: '10.08.2022: This feature gives buyers the ability to enable/disable the PureScore and Research Defender validations for suppliers',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Project Level Data Quality',
          parent: true,
          parentValue: 'enablePureScoreAndRD',
          value: 'enableProjectLevelDataQuality',
          desc: '30.4.2023 - This feature will enable/disable various data quality checks: This is the ability to control the Data Quality settings at the project level Default Settings If Project Level Data Quality DEFAULT setting is "ON" the user will be able to set their default settings. The default setting will be applied to all new projects and will mirror the settings below on the project until changed. Changing a setting in the project only affects that project. If a DEFAULT setting is ON in CONFIG SETTINGS then the DEFAULT setting in the Project will match and be ON.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Default Settings: PureScore',
          parent: true,
          parentValue: 'enablePureScoreAndRD',
          value: 'enableDefaultSettingsPureScore',
          desc: 'This is the ability to block access to a survey for respondents with a low PureScore. "OFF" means that no respondent will be blocked based on PureScore. "ON" means that low-score respondents will be blocked and assigned a status of PS BlackList Fail.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Default Settings: FP Dupe Check',
          parent: true,
          parentValue: 'enablePureScoreAndRD',
          value: 'enableSettingsFpDupeCheck',
          desc: 'This is a device/browser fingerprint-based check for duplicate respondents. "ON" means that duplicate fingerprints will not be permitted to enter the survey and will terminate with PS_DF_DUPE.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Default Settings: FP Fraud Check',
          parent: true,
          parentValue: 'enablePureScoreAndRD',
          value: 'enableSettingsFpFraudCheck',
          desc: 'This is a tool that checks a respondents device/browser fingerprint for a variety of data points common to fraud. ON means that we check for fraudulent configurations, and when identified the Respondent is assigned a status of PS_Transaction_Fraud.',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'Advance Feasibility DropDown',
          value: 'enableAdvanceFeasibilityDropDown',
          desc: 'This feature gives users the ability to have a new experience and an accurate feasibility layout.',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'LOI Multiplier',
          value: 'enableLOIMultiplier',
          desc: '15.09.22: This feature is not ready for release. Do not associate with your account.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Disable Survey Auto-close',
          value: 'disableSurveyAutoclose',
          desc: '11.2.22: this will allow for all buyer surveys to be live until manually closed.',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'Dynamic Reports',
          value: 'enableDynamicReports',
          desc: 'This feature will give users the ability to customize the columns that will be included in their report downloads.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Survey Details Report',
          value: 'enableSurveyDetailsReport',
          desc: 'This feature will allow the buyer to download the supplier summary and feasibility report (Feasibility report available from 06/2023)',
          hasAdditionalInfo: false,
          disabled: false,
        },

        {
          name: 'Privacy Policy',
          value: 'enablePrivacyPolicy',
          desc: '9.11.2023: DO NOT USE THIS FEATURE! This feature will require that respondents entering this buyer’s surveys are asked the Privacy Policy Question',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Transaction testing tool',
          value: 'enableTransactionTestingTool',
          desc: '11/27/3023: This is a tool for enterprise clients to create test completes. Do not use this without consulting Product. This tool will incur additional cost unless configured properly.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Include Fusion publisher ID',
          value: 'includeFusionPublisherId',
          desc: 'This feature will add fusion publisher id in buyer redirect url instead of fusion supplier id',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Entry Link Profile',
          value: 'enableEntryLinkProfile',
          desc: '03/01/2024: This feature will update Respondent Screener to proactively ask profile questions that are included as variables in the Buyer Entry Link',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Segmentation Questions',
          value: 'enableSegmentationClass',
          desc: 'Requires  Dev work, only enabled for specific accounts by product team. Please do not change settings.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Classic STR',
          value: 'enableClassicSTR',
          desc: '04/27/2024: DO NOT USE THIS FEATURE! This feature will restore the classic view on STR for a Buyer including all PS Statuses.',
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Modular survey  (Requires API integration)',
          value: 'enableModularSurvey',
          desc: `16/07/2024: This feature allows a buyer to create a modular survey via API. Buyer can redirect a respondent in a multi-step project and communicate it via API. If the respondent is eligible to take a second study, Marketplace (API) creates and sends a new transaction for same PSID.<br/>
              This feature works only if:<br/><br/>
              <li>Buyer have at least one supplier that agreed to work with Modular survey</li>
              <li>Buyer should integrate the APIs required.</li>
              <li>Modular survey must be managed by a Service Operator</li><br/>
              If you would like to activate this feature, contact the product team.`,
          hasAdditionalInfo: false,
          disabled: false,
        },
        {
          name: 'Enable Real-time Notification (Activity Log)',
          value: 'enableRealTimeNotification',
          desc: '03/06/2024: This feature allows a buyer to get notify in real time any action recored by the activity log. This feature need development effort from the client.',
          hasAdditionalInfo: true,
          disabled: false,
        },
      ],
    },
    {
      section: 'Blocked Countries',
      features: [
        {
          name: 'Blocked Countries',
          value: 'enableBlockedCountries',
          desc: 'This feature can change the default country on Project Creation. It can also remove/add countries to the ‘Country Drop Down’ menu on the create page',
          hasAdditionalInfo: false,
          disabled: false,
        },
      ],
    },
  ],
  THIRTYDAYSMS: 30 * 24 * 60 * 60 * 1000,
  PARENT_FIELDS: [
    'enableAutoFieldManagement',
    'enableTargetMargin',
    'includeToo',
    'enablePureScoreAndRD',
    'enableAdvanceFeasibilityDropDown',
    'enableDynamicDash',
    'enableViewByPM',
    'enableSimilarSurveys',
  ],
});
